// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-chippenham-js": () => import("./../../../src/pages/chippenham.js" /* webpackChunkName: "component---src-pages-chippenham-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-hillside-hall-js": () => import("./../../../src/pages/hillside-hall.js" /* webpackChunkName: "component---src-pages-hillside-hall-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kilmersdon-js": () => import("./../../../src/pages/kilmersdon.js" /* webpackChunkName: "component---src-pages-kilmersdon-js" */),
  "component---src-pages-lessons-js": () => import("./../../../src/pages/lessons.js" /* webpackChunkName: "component---src-pages-lessons-js" */),
  "component---src-pages-orchard-hall-js": () => import("./../../../src/pages/orchard-hall.js" /* webpackChunkName: "component---src-pages-orchard-hall-js" */),
  "component---src-pages-paulton-rovers-club-js": () => import("./../../../src/pages/paulton-rovers-club.js" /* webpackChunkName: "component---src-pages-paulton-rovers-club-js" */),
  "component---src-pages-private-lessons-js": () => import("./../../../src/pages/private-lessons.js" /* webpackChunkName: "component---src-pages-private-lessons-js" */),
  "component---src-pages-reopening-js": () => import("./../../../src/pages/reopening.js" /* webpackChunkName: "component---src-pages-reopening-js" */)
}

