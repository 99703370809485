import { css, Global } from '@emotion/react';
import { motion } from 'framer-motion';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import React, { useState } from 'react';

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        sharp: childImageSharp {
          fixed(width: 233) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `);

  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <>
      <Global
        styles={css`
          .parent-menu > li {
            position: relative;
            background-color: white;
          }
          .parent-menu > li .sub-menu {
            margin-left: 1rem;
          }

          .parent-menu li.hidden {
            display: block;
          }

          @media screen and (min-width: 768px) {
            .parent-menu > li {
              display: inline-block;
            }
            .parent-menu li.hidden {
              display: none;
            }
            .parent-menu li a:hover {
              text-decoration: underline;
            }
            .parent-menu li .sub-menu {
              margin-left: 0;
            }

            .sub-menu {
              visibility: hidden; /* hides sub-menu */
              position: absolute;
              background-color: black;
              color: white;
              opacity: 0;
              top: 100%;
              left: 0;
              width: 100%;
              transform: translateY(-0.5em);
              transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s;
            }

            .parent-menu li:hover .sub-menu {
              visibility: visible; /* shows sub-menu */
              opacity: 1;
              transform: translateY(0%);
              transition-delay: 0s, 0s;
            }
          }
        `}
      />
      <header>
        <div className="bg-white hidden px-6 md:flex md:justify-end pt-2">
          <motion.a
            initial={{ opacity: 0 }}
            animate={{ scaleX: [1, 0, 1], opacity: 1 }}
            transition={{ delay: 1.75, duration: 0.5 }}
            href="https://www.facebook.com/thebathdancentre/"
            title="Join us on Facebook!"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              css={css`
                width: 1.5rem;
                overflow: visible;
                transition: color 0.25s ease;

                &:hover {
                  color: #4d69a2;
                }
              `}
            >
              <path
                fill="currentColor"
                d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
              ></path>
            </svg>
          </motion.a>
        </div>
        <div className="bg-white flex flex-wrap items-center justify-between p-4 pt-1 md:pb-5 border-black border-b shadow-md">
          <Link to="/" className="mt-3" aria-label="home page">
            <Image
              fixed={data.logo.sharp.fixed}
              alt="Logo"
              loading="eager"
              fadeIn={false}
            />
          </Link>

          <button
            className="md:hidden border border-white flex items-center px-3 py-2 rounded"
            onClick={() => toggleExpansion(!isExpanded)}
          >
            <svg
              className="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>

          <nav
            className={`${
              isExpanded ? `block` : `hidden`
            } w-full md:flex md:items-center md:w-auto`}
          >
            <ul className="parent-menu flex flex-col md:block text-md tracking-wide font-display">
              <li className="border-black mt-2 md:border-r md:px-5 md:py-3 md:mt-0">
                <Link to="/" activeClassName="underline font-semibold">
                  Home
                </Link>
              </li>
              {/* <li className="border-black mt-2 md:border-r md:px-5 md:py-3 md:mt-0">
                <Link to="/lessons" activeClassName="underline font-semibold">
                  Lessons
                </Link>
                <ul className="sub-menu md:text-center md:shadow-md">
                  <li className="mt-2 md:px-5 md:py-3 md:mt-0">
                    <Link
                      to="/private-lessons"
                      activeClassName="underline font-semibold"
                    >
                      Private
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="border-black mt-2 md:border-r md:px-5 md:py-3 md:mt-0">
                <Link to="/events" activeClassName="underline font-semibold">
                  Events
                </Link>
              </li> */}
              <li className="border-black mt-2 md:border-r md:px-5 md:py-3 md:mt-0">
                <Link to="/about" activeClassName="underline font-semibold">
                  About
                </Link>
              </li>
              <li className="mt-1 md:px-5 md:py-3 md:mt-0">
                <Link to="/contact" activeClassName="underline font-semibold">
                  Contact
                </Link>
              </li>
              <li className="text-center mt-3 font-semibold text-highlight text-sm hidden">
                <a href="https://www.facebook.com/thebathdancentre/">
                  Join us on Facebook!
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
