import PropTypes from 'prop-types';
import React from 'react';
import Header from './header';

function Layout({ children }) {
  return (
    <div className="font-body flex flex-col min-h-screen bg-white text-gray-900 w-full max-w-5xl mx-auto overflow-x-hidden">
      <Header />

      <main className="bg-white flex flex-col flex-1 md:justify-start max-w-5xl mx-auto px-4 pt-6 pb-8 w-full">
        {children}
      </main>

      <footer className="mt-4">
        <nav className="bg-black flex justify-between max-w-5xl mx-auto p-4 md:p-3 text-sm">
          <p className="text-white">
            Created by{' '}
            <a
              href="mailto:john.balke@gmail.com"
              className="font-semibold no-underline text-white"
            >
              John Balke &#169;{new Date().getFullYear()}
            </a>
          </p>
        </nav>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
